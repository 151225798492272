import React from "react"
import Layout from "../components/layout"

export default function Home() {
  return (
    <Layout>
      <div className="row">
        <div className="col-sm">
          <h1>Lorena Cut Color Styling</h1>
          <p>Unsere Priorität liegt darin Sie zu verwöhnen und aus Ihrem Alltag herauszuholen.
            Ein kleines Wellness Programm um abzuschalten und die Sorgen vergessen zu können.
            Egal ob Mann - Frau - Diverse- Mama - Papa - Oma - Opa bei uns zählt der Mensch der einfach mal wieder eine entspannte Auszeit für sich selbst benötigt.
            Vom Haarschnitt bis hin zur Farblichen Veränderung.
            Unsere Einstellung bezüglich der Colorations Behandlungen sind auf das Haarschondeste zu reduzieren, damit Sie ein schönes Haargefühl mit nach Hause nehmen.
            Neben den Haarpflegeprodukte der Firmen Kerastase , Loreal, American Crew und Revlon arbeiten wir im Colortionsbereich mit einigen amonikfreien Haarcoloration, die natürliche Ergebnise erzielen.
            Lassen Sie sich selbst von unserer Vielfalt überzeugen. Wir würden uns freuen Sie demnächst als Kunden bedienen zu dürfen.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          col
        </div>
        <div className="col-sm">
          col
        </div>
      </div>
      <div>Hello world!</div>
    </Layout>
  )
}
